'use client';

import Link from 'next/link';
import React from 'react';

/**
 * Use this component to create a Next.js `<Link />` that persists the current country code in the url,
 * without having to explicitly pass it as a prop.
 */
const LocalizedClientLink = ({
	children,
	href,
	onClick,
	passHref,
	className,
	...props
}: {
	children?: React.ReactNode;
	href: string;
	className?: string;
	onClick?: () => void;
	passHref?: true;
	[x: string]: any;
}) => {
	return (
		<Link href={`/${href}`} {...props} className={className}>
			{children}
		</Link>
	);
};

export default LocalizedClientLink;
