import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "__barrel_optimize__?names=Badge!=!/vercel/path0/storefront/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "__barrel_optimize__?names=Button!=!/vercel/path0/storefront/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["FloatButton"] */ "__barrel_optimize__?names=FloatButton!=!/vercel/path0/storefront/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/modules/common/components/localized-client-link/index.tsx");
